import { BrowseFilter, BrowseFilterCheckbox } from '.';

export function BrowseFilterAttributeBoolean({
  label,
  name,
  checked,
  onChange,
}: {
  label: string;
  name: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <BrowseFilter>
      <BrowseFilterCheckbox name={name} checked={checked} onChange={onChange}>
        {label}
      </BrowseFilterCheckbox>
    </BrowseFilter>
  );
}
