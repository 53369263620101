import { BrowseFilterCheckbox } from '.';
import { BrowseFilter } from '.';
import { useCallback, useMemo, useState } from 'react';
import checkDependency from '~/lib/attributes/deps';
import type { DealAttributeOption } from '../types';

/**
 * Browse filter for a multiple enumeration deal attribute.
 */
export function BrowseFilterAttributeEnumerationMulti({
  title,
  name,
  options,
  values = [],
  filters = {},
  onChange,
  onPrefetchChange,
  initialResults = 5,
  maximumResults = 50,
}: {
  title: string;
  name: string;
  options: DealAttributeOption[];
  values: string[];
  filters: any;
  onChange: (values: string[]) => void;
  onPrefetchChange: (values: string[]) => void;
  initialResults?: number;
  maximumResults?: number;
}) {
  const [showAll, setShowAll] = useState(false);

  // add or remove an option to the array of values depending on if it was checked or not
  const onToggleOption = useCallback(
    (value: string, checked: boolean) => {
      let newValues;
      if (checked) {
        newValues = ([] as string[]).concat(...values, value);
      } else {
        newValues = values.filter((v) => v !== value);
      }
      // sort alphabetically to ensure consistent URLs
      newValues.sort((a, b) => a.localeCompare(b));
      onChange(newValues);
    },
    [values, onChange],
  );

  const onPrefetchToggleOption = useCallback(
    (value: string, checked: boolean) => {
      let newValues;
      if (checked) {
        newValues = ([] as string[]).concat(...values, value);
      } else {
        newValues = values.filter((v) => v !== value);
      }
      // sort alphabetically to ensure consistent URLs
      newValues.sort((a, b) => a.localeCompare(b));
      onPrefetchChange(newValues);
    },
    [values, onPrefetchChange],
  );

  // we only render options that are fulfilled by dependencies, and trimmed to showAll or not
  const optionsToRender = useMemo(
    () =>
      options
        .filter((option) => checkDependency(option.dependency, filters))
        .slice(0, showAll ? maximumResults : initialResults),
    [options, filters, initialResults, maximumResults, showAll],
  );

  return (
    <BrowseFilter title={title}>
      {optionsToRender.map((option) => (
        <BrowseFilterCheckbox
          key={option.value}
          name={name}
          className="my-2.5"
          checked={values.includes(option.value)}
          onChange={(checked) => onToggleOption(option.value, checked)}
          onPrefetchChange={(checked) =>
            onPrefetchToggleOption(option.value, checked)
          }
        >
          <span className="grow">{option.name}</span>
          <span className="text-sm font-normal text-gray-500">
            ({option.doc_count})
          </span>
        </BrowseFilterCheckbox>
      ))}
      {options.length > initialResults && (
        <button
          className="text-blue-600 underline"
          onClick={() => setShowAll(!showAll)}
        >
          Show {showAll ? 'less' : 'all'}
        </button>
      )}
    </BrowseFilter>
  );
}
