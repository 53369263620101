import Image from 'next/image';
import { BrowseFilterCheckbox } from '.';
import logo from '../../../public/appsumo-select.svg';
import { BrowseFilter } from '.';
import question from '../../../public/question-mark.svg';
import { Popover } from '~/components/ui/Popover';

/**
 * AppSumo Select browse filter.
 */
export function BrowseFilterSelect({
  name,
  checked,
  onChange,
  onPrefetchChange = () => {},
}: {
  /**
   * Name of the form input.
   */
  name: string;
  /**
   * Checked state of the checkbox.
   */
  checked: boolean;
  /**
   * Callback for when the checkbox is toggled.
   */
  onChange: (value: boolean) => void;
  /**
   * Prefetch handler for when the checkbox is toggled.
   */
  onPrefetchChange?: (value: boolean) => void;
}) {
  return (
    <BrowseFilter>
      <div className="flex w-full">
        <BrowseFilterCheckbox
          name={name}
          checked={checked}
          onChange={onChange}
          onPrefetchChange={onPrefetchChange}
          className="grow"
        >
          <div>
            <Image src={logo} height="14" alt="AppSumo Select" />
          </div>
        </BrowseFilterCheckbox>
        <Popover
          content={`Exclusive offers on the hottest software, expertly tested, vetted, and brought to you by the AppSumo team.`}
        >
          <div>
            <Image src={question} height="24" alt="" />
          </div>
        </Popover>
      </div>
    </BrowseFilter>
  );
}
