/* @ts-ignore */
import React from 'react';

import { BlueEmailSubscribe } from './BlueEmailSubscribe';
import { RoadblockProps } from './types';

export const Roadblock = function ({ type, componentName }: RoadblockProps) {
  if (type === 'blue-email-subscribe') {
    return <BlueEmailSubscribe componentName={componentName} />;
  }

  return <></>;
};
