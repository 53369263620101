import { useState, useMemo, useEffect, memo } from 'react';
import {
  faArrowUp,
  faCheck,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import {
  Button,
  CountdownTimer,
  Heading,
  TacoRating,
  ThumbnailGallery,
} from '@appsumo/dorado-react';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';

import { DealDownloads } from '~/components/product';
import {
  DealSkuCardType,
  DealUniquePlanType,
  DealCommonFeaturesType,
} from '~/components/sku/types';
import { Link } from '~/components/global/Link';

import { currency } from '~/lib/format';
import { useGetSkuBannerDetails } from '~/lib/browse/hooks';
import { fetchJson } from '~/lib/fetch';
import useUser from '~/lib/user';
import { LOGIN_URL } from '~/lib/util/constants';

interface GalleryImageProp {
  src: string;
  alt?: string;
}

interface StoryImageProp {
  url: string;
  alt_text?: string;
}

interface ShowMoreButtonProps {
  isShowMore: boolean;
  setIsShowMore: (showMore: boolean) => void;
}

const parseImages = (deal: DealSkuCardType): GalleryImageProp[] => {
  if (!deal) return [];

  const { story_images, public_name, media_url } = deal;

  // Have the media url the first image of the gallery
  const imageList =
    story_images?.reduce((m: GalleryImageProp[], image: StoryImageProp) => {
      if (media_url !== image.url) {
        m.push({
          src: image.url,
          alt: image.alt_text || `${public_name} Image`,
        });
      }

      return m;
    }, []) || [];

  if (media_url) {
    imageList.unshift({
      src: media_url,
      alt: public_name || `${public_name} Image`,
    });
  }

  return imageList;
};

export const Attribute = memo(
  ({
    label,
    attribute,
  }: {
    label: string;
    attribute: string[] | undefined;
  }) => {
    if (!attribute) return <></>;

    return (
      <dl>
        <dt className="text-midnight md:text-grace">
          <Heading.H4 className="max-sm:!text-base">{label}:</Heading.H4>
        </dt>
        <dd>{attribute.join(', ')}</dd>
      </dl>
    );
  },
);

Attribute.displayName = 'Attribute';

export const TopFeatures = memo(
  ({
    children,
    features,
  }: {
    children?: React.ReactNode;
    features: DealCommonFeaturesType[];
  }) => {
    const featureList = useMemo(
      () =>
        features?.slice(0, 4).map((feature) => {
          return (
            <li className="flex items-start gap-x-2" key={feature.feature}>
              <FontAwesomeIcon
                icon={faCheck}
                className="text-xl text-green-500"
              />{' '}
              <div
                className="line-clamp-2"
                dangerouslySetInnerHTML={{ __html: feature.feature }}
              />
            </li>
          );
        }),
      [features],
    );

    if (!features?.length) return <></>;

    return (
      <div className="sm:w-1/2 lg:w-full">
        {children}
        <ul className="flex flex-col gap-y-2 md:gap-y-4">{featureList}</ul>
      </div>
    );
  },
);

TopFeatures.displayName = 'TopFeatures';

const Price = ({
  price,
  planTypes,
}: {
  price: number;
  planTypes?: DealUniquePlanType[];
}) => {
  const { priceStr, planStr } = useMemo(() => {
    let priceStr = '';
    let planStr = '/';

    if (price === 0) {
      priceStr = 'FREE';
    } else if (price > 0) {
      priceStr = currency(price);
    }

    // TODO: Update with default if more than 1 unique plan types
    if (planTypes?.length) {
      const [planType, planInterval, intervalCount] = planTypes[0];

      if (['One Time Purchase', 'Lifetime Deal'].includes(planType)) {
        planStr += 'lifetime';
      } else if (planType === 'Limited License') {
        planStr +=
          planInterval === 'year' && intervalCount === 1
            ? 'annual'
            : `${intervalCount} ${planInterval}`;
      } else if (planType) {
        planStr += planType;
      } else {
        planStr = '';
      }
    }

    return { priceStr, planStr };
  }, [price, planTypes]);

  return (
    <p>
      <span className="text-2xl font-bold text-midnight">{priceStr}</span>
      <span className="text-sm lowercase">{planStr}</span>
    </p>
  );
};

const ShowMoreButton = ({ setIsShowMore, isShowMore }: ShowMoreButtonProps) => {
  return (
    <button
      className="font-header font-bold text-bolt"
      onClick={() => setIsShowMore(!isShowMore)}
    >
      <span className="mr-3">View {isShowMore ? 'less' : 'more'}</span>
      <FontAwesomeIcon
        icon={faChevronDown}
        className={`transition duration-75 ease-in-out ${
          isShowMore ? 'rotate-180' : ''
        }`}
      />
    </button>
  );
};

const VoteButton = ({
  dealId,
  hasVoted,
  votes,
  voteCallback,
  disabled,
}: {
  dealId: number;
  hasVoted: boolean;
  votes: number;
  voteCallback: Function;
  disabled: boolean;
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [dealVotes, setDealVotes] = useState(votes);
  const [userHasVoted, setUserHasVoted] = useState(hasVoted);

  useEffect(() => {
    setDealVotes(votes);
    setUserHasVoted(hasVoted);
  }, [hasVoted, votes]);

  const { user } = useUser();

  const onVote = () => {
    // only auth user can vote
    if (!user?.id) {
      window.location.href = `${LOGIN_URL}?next=${window.location.pathname}`;
      return;
    }

    if (disabled || isProcessing) {
      return;
    }

    setIsProcessing(true);

    const url = `/api/v2/deals/${dealId}/vote/`;
    const data = { direction: userHasVoted ? '0' : '1' };

    fetchJson(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify(data),
    })
      .then(() => {
        setDealVotes(userHasVoted ? dealVotes - 1 : dealVotes + 1);
        setUserHasVoted(!userHasVoted);
      })
      .catch((error) => {
        console.error('Voting Deal - SKU Alternative:', error);
        toast.error('Something went wrong. Please try again later.');
      })
      .then(() => {
        setIsProcessing(false);
        voteCallback();
      });
  };

  return (
    <button
      className={clsx(
        'group flex min-h-[28px] flex-col items-center justify-center gap-2 rounded border p-2 pt-4 transition-colors',
        disabled ? 'bg-iceberg' : 'hover:border-dollar',
        userHasVoted ? 'border-dollar' : 'border-sundog',
      )}
      disabled={disabled || isProcessing}
      onClick={onVote}
    >
      <FontAwesomeIcon
        icon={faArrowUp}
        className={`text-xl ${disabled ? 'text-slate' : 'text-green-500'}`}
      />
      <span className={`font-bold ${disabled ? 'text-grace' : ''}`}>
        {dealVotes}
      </span>
      <span
        className={clsx('w-20 rounded p-2 font-bold transition-colors', {
          'bg-transparent text-slate': disabled,
          'bg-iceberg group-hover:bg-dollar': userHasVoted,
          'bg-sundog group-hover:bg-dollar': !disabled && !userHasVoted,
        })}
      >
        Vote
      </span>
    </button>
  );
};

export const AlternativeTo = ({
  deal,
  enableVoting = false,
  mutate = () => {},
  votedDealId,
  showOnlyMainInfo = false,
}: {
  deal: DealSkuCardType;
  enableVoting?: boolean;
  mutate?: () => void;
  votedDealId?: number;
  showOnlyMainInfo?: boolean;
}): React.ReactElement => {
  const [isShowMore, setIsShowMore] = useState(false);

  const imageList = useMemo(() => parseImages(deal), [deal]);
  const { bannerText, bannerType } = useGetSkuBannerDetails(deal);
  const showPricing = !['coming_soon', 'sold_out'].includes(bannerType);

  return (
    <div className="flex flex-col gap-4 rounded-lg border border-sundog bg-white p-4 text-midnight shadow max-sm:pb-4 sm:p-8 md:items-start lg:flex-row lg:gap-x-8 lg:p-8">
      <div
        className={`flex justify-between gap-4 ${
          !showOnlyMainInfo ? 'lg:w-1/2' : 'w-full'
        }`}
      >
        <div
          className={`flex flex-col gap-6 sm:flex-row ${
            showOnlyMainInfo ? 'w-full' : ''
          }`}
        >
          <div
            className={`relative flex w-full flex-col items-center gap-4 max-sm:hidden ${
              showOnlyMainInfo ? 'md:max-w-xs' : ''
            }`}
          >
            <div className="w-full">
              <ThumbnailGallery
                bannerType={bannerType}
                bannerText={bannerText}
                galleryImages={imageList}
                className={`overflow-hidden rounded object-cover ${
                  showOnlyMainInfo ? 'aspect-sku-card w-full' : ''
                }`}
                image={{
                  src: deal.media_url,
                  alt: deal.public_name,
                }}
              />
            </div>
            <Button
              className="w-full text-center max-sm:hidden lg:w-auto 2xl:w-1/2"
              href={`${deal.get_absolute_url}`}
            >
              View details
            </Button>
          </div>
          <div className="mb-4 sm:hidden">
            <Link href={deal.get_absolute_url}>
              <Image
                src={deal.media_url}
                width="0"
                alt={deal.public_name}
                height="0"
                sizes="100vw"
                className="h-auto w-full rounded"
              />
            </Link>
          </div>
          <div className="flex flex-col sm:w-1/2">
            <Link href={deal.get_absolute_url}>
              <Heading.H3 className="sm:text-2xl">
                {deal.public_name}
              </Heading.H3>
            </Link>
            <div
              className="text-sm line-clamp-3 sm:line-clamp-5 md:mt-2 md:text-base"
              dangerouslySetInnerHTML={{ __html: deal.card_description }}
            />
            <div className="relative flex flex-col flex-wrap">
              <div className="mt-2">
                {deal.is_free && (
                  <DealDownloads
                    downloads={deal.freebie_downloads_total}
                    href={`${deal.get_absolute_url}`}
                    className="max-sm:m-0"
                  />
                )}
                {!deal.is_free && (
                  <TacoRating
                    averageRating={deal.deal_review?.average_rating || 5}
                    size="sm"
                    href={`${deal.get_absolute_url}#reviews`}
                    reviewCount={String(deal.deal_review?.review_count || 5)}
                  />
                )}
              </div>
              {showPricing && (
                <div className="mt-2">
                  <Price
                    price={deal?.price}
                    planTypes={deal?.unique_plan_types}
                  />
                </div>
              )}
            </div>
            {deal.active_timer && deal.dates?.end_date && (
              <div className="mt-2 flex md:mt-4">
                <CountdownTimer
                  datetime={deal.dates.end_date}
                  style="red-pill"
                />
              </div>
            )}
          </div>
        </div>

        {enableVoting && !showOnlyMainInfo && (
          <div className="lg:hidden">
            <VoteButton
              dealId={deal.id}
              hasVoted={deal.has_user_voted}
              votes={deal.total_votes_count}
              voteCallback={mutate}
              disabled={!!votedDealId && votedDealId !== deal.id}
            />
          </div>
        )}
      </div>
      {!showOnlyMainInfo && (
        <>
          <div className="w-full text-center lg:hidden">
            <ShowMoreButton
              setIsShowMore={setIsShowMore}
              isShowMore={isShowMore}
            />
          </div>
          <div
            className={`flex items-start overflow-hidden text-sm transition-all sm:text-base lg:w-1/2 ${
              isShowMore
                ? 'max-lg:max-h-[600px]'
                : 'max-lg:-mt-4 max-lg:max-h-0'
            }`}
          >
            <div className="flex flex-col gap-x-8 gap-y-3 sm:flex-row lg:flex-col">
              <TopFeatures features={deal.common_features}>
                <Heading.H4 className="mb-2 !text-base sm:hidden">
                  Top Features:
                </Heading.H4>
              </TopFeatures>
              <div className="flex flex-col gap-y-3 sm:w-1/2 lg:w-full">
                <Attribute
                  label="Best for"
                  attribute={deal?.attributes?.best_for}
                />
                <Attribute
                  label="Integrations"
                  attribute={deal?.attributes?.integrations}
                />
              </div>
            </div>

            {enableVoting && (
              <div className="ml-auto hidden lg:block">
                <VoteButton
                  dealId={deal.id}
                  hasVoted={deal.has_user_voted}
                  votes={deal.total_votes_count}
                  voteCallback={mutate}
                  disabled={!!votedDealId && votedDealId !== deal.id}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
