import Image from 'next/image';
import { BrowseFilterCheckbox } from '.';
import logo from '../../../public/black-friday-badge.svg';
import { BrowseFilter } from '.';
import question from '../../../public/question-mark.svg';
import { Popover } from '~/components/ui/Popover';

/**
 * Black Friday browse filter.
 */
export function BrowseFilterBlackFriday({
  name,
  checked,
  onChange,
  onPrefetchChange,
}: {
  /**
   * Name of the form input.
   */
  name: string;
  /**
   * Checked state of the checkbox.
   */
  checked: boolean;
  /**
   * Callback for when the checkbox is toggled.
   */
  onChange: (value: boolean) => void;
  /**
   * Prefetch handler for when the checkbox is toggled.
   */
  onPrefetchChange?: (value: boolean) => void;
}) {
  return (
    <BrowseFilter>
      <div className="flex w-full">
        <BrowseFilterCheckbox
          name={name}
          checked={checked}
          onChange={onChange}
          onPrefetchChange={onPrefetchChange}
          className="grow"
        >
          <div>
            <Image src={logo} height="20" alt="Black Friday" />
          </div>
        </BrowseFilterCheckbox>
        <Popover
          content={`Show Black Friday eligible tools only.  Add $150+ of these tools to your cart to unlock 10% off.`}
        >
          <div>
            <Image src={question} height="24" alt="" />
          </div>
        </Popover>
      </div>
    </BrowseFilter>
  );
}
