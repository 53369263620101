interface Dependency {
  [key: string]: string[];
}

interface Values {
  [key: string]: string | undefined;
}

export default function checkDependency(
  dependency: Dependency,
  values: Values,
): boolean {
  if (!dependency || Object.keys(dependency).length === 0) return true;

  const deps = Object.keys(dependency).filter((key) => {
    const required = dependency[key];
    const value = values[key];

    if (typeof value === 'undefined') return false;

    // an empty array means we just need any value to be set
    if (
      required.length === 0 ||
      (required.length === 1 && required[0] === '')
    ) {
      if (key in values) return true;
      return false;
    }
    if (!required.includes(value)) return false;

    return true;
  });

  return deps.length === Object.keys(dependency).length;
}
