import Image from 'next/image';
import clsx from 'clsx';
import { Link } from '@appsumo/dorado-react';
import asksumoPill from '~/public/asksumo.svg';

import useUser from '~/lib/user';

export function AskSumoBrowse({ className }: { className?: string }) {
  const { user } = useUser();
  if (!user?.id) return null;

  const containerClasses = clsx(
    "mb-6 flex items-start rounded-b border border-t-4 border-t-[#BCF2CB] bg-[url('~/public/asksumoBgMobile.png')] bg-cover bg-no-repeat px-2 py-5",
    "md:flex-col md:items-center md:bg-[url('~/public/asksumoBg.png')] md:px-1 md:pt-4 md:text-center",
    className,
  );

  return (
    <Link href="/asksumo/?ref=browse" className={containerClasses}>
      <Image
        src={asksumoPill}
        alt="asksumo logo"
        height={32}
        className="mr-3 md:mr-0 md:mb-2"
      />
      <div style={{ fontFamily: 'var(--font-barlow)' }}>
        <p>Can&apos;t find what you&apos;re looking for?</p>
        <p className="font-bold">Search alternatives on AskSumo.</p>
        <div className="mt-2 inline-block rounded-full bg-black py-1 px-4 text-sm font-bold text-white">
          Find deals
        </div>
      </div>
    </Link>
  );
}
