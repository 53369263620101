import React, { useId, ReactNode, useCallback } from 'react';

/**
 * Radio button filter.
 */
export function BrowseFilterRadio({
  onChange,
  onPrefetchChange = () => {},
  name,
  value,
  checked,
  children,
  className = '',
}: {
  /**
   * Name of the form input.
   */
  name: string;
  /**
   * Value of the form input.
   */
  value: string;
  /**
   * Checked state of the radio button.
   */
  checked: boolean;
  /**
   * Callback for when the radio button is toggled.
   */
  onChange: (value: string) => void;
  /**
   * Prefetch handler for when the radio button is toggled.
   */
  onPrefetchChange?: (value: string) => void;
  /**
   * Additional class names to apply to the root element.
   */
  className?: string;
  children: ReactNode;
}) {
  const id = `browse-filter-${useId()}`;

  const onMouseEnter = useCallback(() => {
    onPrefetchChange(value);
  }, [onPrefetchChange, value]);

  return (
    <div
      className={`flex items-center ${className}`}
      onMouseEnter={onMouseEnter}
    >
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.target.value)}
        className="h-6 w-6 cursor-pointer rounded-full border-black"
      />
      <label
        htmlFor={id}
        className="ml-2 flex w-full cursor-pointer items-center font-header font-semibold"
      >
        {children}
      </label>
    </div>
  );
}
