import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import React, { ReactNode, useState } from 'react';

/**
 * Expandable filter container.
 */
export function BrowseFilter({
  title,
  open = false,
  children,
  className = '',
}: {
  /**
   * Title of the filter.
   */
  title?: string;
  /**
   * Whether the filter is open by default.
   */
  open?: boolean;
  children: ReactNode;
  /**
   * Additional class names to apply to the root element.
   */
  className?: string;
}) {
  const [collapsed, setCollapsed] = useState(!open);

  return (
    <div className={`mb-4 border-b border-gray-200 pb-4 ${className}`}>
      {title && (
        <>
          <button
            className="flex w-full items-center justify-between"
            onClick={() => setCollapsed(!collapsed)}
          >
            <span className="font-header font-semibold">{title}</span>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`inline-block text-sm transition duration-300 ease-in-out ${
                collapsed ? '' : 'rotate-180'
              }`}
            />
          </button>
          {!collapsed && (
            <div className={`mt-4 ${collapsed ? 'hidden' : 'block'}`}>
              {children}
            </div>
          )}
        </>
      )}
      {!title && children}
    </div>
  );
}
