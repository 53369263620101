import { useContext } from 'react';
import Image from 'next/image';

import { BrowseFilterCheckbox } from '~/components/browse/filters/BrowseFilterCheckbox';
import { BrowseFilter } from '~/components/browse/filters/BrowseFilter';
import { Popover } from '~/components/ui/Popover';
import { GlobalContext } from '~/contexts/global';
import question from '~/public/question-mark.svg';

/**
 * Campaign browse filter
 * @param {string} name - Name of the form input.
 * @param {boolean} checked - Checked state of the checkbox.
 * @param {function} onChange - Callback for when the checkbox is toggled.
 * @param {function} onPrefetchChange - Prefetch handler for when the checkbox is toggled.
 */
export function BrowseFilterCampaign({
  name,
  checked,
  onChange,
  onPrefetchChange,
}: {
  name: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  onPrefetchChange?: (value: boolean) => void;
}) {
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);
  const campaignBrowseLogo =
    activeAutoCampaign?.campaign_config?.browse_filter_logo;
  if (!activeAutoCampaign || !campaignBrowseLogo) return null;

  return (
    <BrowseFilter>
      <div className="flex w-full">
        <BrowseFilterCheckbox
          name={name}
          checked={checked}
          onChange={onChange}
          onPrefetchChange={onPrefetchChange}
          className="grow"
        >
          <div className="relative h-full w-full">
            <Image
              src={`${campaignBrowseLogo}?width=320`}
              alt={
                activeAutoCampaign.campaign_config?.browse_filter_logo_alt ?? ''
              }
              width={90}
              height={20}
              unoptimized
              className="!relative !h-5 !w-auto"
            />
          </div>
        </BrowseFilterCheckbox>
        <Popover
          content={`Show ${activeAutoCampaign.name} eligible tools only.`}
        >
          <div>
            <Image src={question} height="24" alt="" />
          </div>
        </Popover>
      </div>
    </BrowseFilter>
  );
}
