import { BrowseFilterRadio } from '.';
import { BrowseFilter } from '.';
import { useState, useMemo } from 'react';
import checkDependency from '~/lib/attributes/deps';
import type { DealAttributeOption } from '../types';

/**
 * Browse filter for a single enumeration deal attribute.
 */
export function BrowseFilterAttributeEnumerationSingle({
  title,
  name,
  options,
  value,
  filters,
  initialResults = 5,
  maximumResults = 50,
  onChange,
  onPrefetchChange,
}: {
  title: string;
  name: string;
  value: string;
  options: DealAttributeOption[];
  filters: any;
  initialResults?: number;
  maximumResults?: number;
  onChange: (value?: string) => void;
  onPrefetchChange: (value?: string) => void;
}) {
  const [showAll, setShowAll] = useState(false);

  // we only render options that are fulfilled by dependencies, and trimmed to showAll or not
  const optionsToRender = useMemo(
    () =>
      options
        .filter((option) => checkDependency(option.dependency, filters))
        .slice(0, showAll ? maximumResults : initialResults),
    [options, filters, initialResults, maximumResults, showAll],
  );
  return (
    <BrowseFilter title={title}>
      <BrowseFilterRadio
        name={name}
        className="my-2.5"
        checked={!value}
        value=""
        onChange={() => onChange(undefined)}
        onPrefetchChange={() => onPrefetchChange(undefined)}
      >
        <span className="grow">All {title.toLowerCase()}</span>
      </BrowseFilterRadio>
      {optionsToRender.map((option) => (
        <BrowseFilterRadio
          key={option.value}
          name={name}
          className="my-2.5"
          checked={value === option.value}
          value={option.value}
          onChange={(value) => onChange(value)}
          onPrefetchChange={(value) => onPrefetchChange(value)}
        >
          <span className="grow">{option.name}</span>
          <span className="text-sm font-normal text-gray-500">
            ({option.doc_count})
          </span>
        </BrowseFilterRadio>
      ))}
      {options.length > initialResults && (
        <button
          className="text-blue-600 underline"
          onClick={() => setShowAll(!showAll)}
        >
          Show {showAll ? 'less' : 'all'}
        </button>
      )}
    </BrowseFilter>
  );
}
