import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useContext, useMemo } from 'react';
import type { DealAttributeOption } from '../types';
import { GlobalContext } from '~/contexts/global';

function AppliedFilter({
  type,
  label,
  onClick,
}: {
  type: string;
  label: string;
  onClick: () => void;
}) {
  return (
    <button
      className="relative mb-2.5 flex cursor-pointer flex-col whitespace-nowrap rounded bg-gray-300 py-1 px-4 pr-10"
      onClick={onClick}
    >
      <span className="text-sm">{type}</span>
      <span>{label}</span>
      <FontAwesomeIcon
        icon={faTimes}
        className="absolute right-4 top-[50%] -mt-2"
      />
    </button>
  );
}

export function BrowseAppliedFilters({
  filters,
  profileParams = {},
  dealAttributes,
  onChange,
  onChangeAttributes,
  onClear,
}: {
  filters: any;
  dealAttributes: any;
  onChange: (key: string | {}, value?: string) => void;
  onChangeAttributes: (attributes: {}) => void;
  onClear: () => void;
  profileParams: {};
}) {
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);
  const appliedFilters = useMemo(() => {
    const { attributes = {} } = filters;
    const appliedFilters = [];
    const defaultFilters = Object.keys(profileParams);
    const defaultStatus = 'current';

    if (
      String(filters.marketplace).toLowerCase() === 'false' &&
      !defaultFilters.includes('marketplace')
    ) {
      appliedFilters.push({
        type: 'AppSumo Select',
        label: 'Select only',
        handler: () => onChange('marketplace', undefined),
      });
    }

    if (
      filters.campaign &&
      activeAutoCampaign &&
      !defaultFilters.includes('campaign')
    ) {
      const campaignName = activeAutoCampaign?.name || 'Campaign';
      appliedFilters.push({
        type: campaignName,
        label: `${campaignName} only`,
        handler: () => onChange('campaign', undefined),
      });
    }

    let label;

    if (filters.type) {
      switch (filters.type) {
        case 'lifetime-deal':
          label = 'Lifetime deal';
          break;
        case 'limited-license':
          label = 'Annual';
          break;
        case 'freebie':
          label = 'Freebie';
          break;
        case 'subscription':
          label = 'Subscription';
          break;
        case 'digital-download':
          label = 'Digital download';
          break;
        default:
          label = null;
          break;
      }

      if (label) {
        appliedFilters.push({
          type: 'Plan type',
          label,
          handler: () => onChange('type', undefined),
        });
      }
    }

    if (
      !defaultFilters.includes('status') &&
      filters.status !== defaultStatus
    ) {
      if (filters.status) {
        switch (filters.status) {
          case 'ending':
            label = 'Ending soon';
            break;
          case 'expired':
            label = 'Unavailable';
            break;
          case 'all':
            label = 'All';
            break;
          case 'current':
            label = 'Current';
            break;
          default:
            label = null;
            break;
        }

        if (label) {
          appliedFilters.push({
            type: 'Status',
            label,
            handler: () => onChange('status', undefined),
          });
        }
      }
    }

    label = null;
    if (filters.price_type === 'free') {
      label = 'Free';
      appliedFilters.push({
        type: 'Price',
        label: 'Free',
        handler: () => onChange('price_type', undefined),
      });
    } else if (filters.price_from || filters.price_to) {
      if (filters.price_from && filters.price_to) {
        label = `$${filters.price_from} - $${filters.price_to}`;
      } else if (filters.price_from) {
        label = `$${filters.price_from} -`;
      } else if (filters.price_to) {
        label = `$${filters.price_to}`;
      }

      if (label) {
        appliedFilters.push({
          type: 'Price',
          label,
          handler: () =>
            onChange({ price_from: undefined, price_to: undefined }),
        });
      }
    }

    dealAttributes.forEach((dealAttribute: any) => {
      if (
        !dealAttribute.can_filter ||
        ['group', 'category', 'subcategory'].includes(dealAttribute.slug)
      )
        return;

      const value = attributes[dealAttribute.slug];

      if (typeof value !== 'undefined') {
        if (dealAttribute.type === 'enumeration') {
          if (dealAttribute.type_options?.multiple === true) {
            const options = dealAttribute.type_options?.options.filter(
              (option: any) => value.includes(option.value),
            );

            options.forEach((option: DealAttributeOption) => {
              appliedFilters.push({
                type: dealAttribute.name,
                label: option.name,
                handler: () =>
                  onChangeAttributes({
                    [dealAttribute.slug]: value.filter(
                      (v: string) => v !== option.value,
                    ),
                  }),
              });
            });
          } else {
            const option = dealAttribute.type_options?.options.find(
              (option: any) => option.value === value,
            );

            if (option) {
              appliedFilters.push({
                type: dealAttribute.name,
                label: option.name,
                handler: () =>
                  onChangeAttributes({
                    [dealAttribute.slug]: undefined,
                  }),
              });
            }
          }
        } else if (dealAttribute.type === 'boolean') {
          appliedFilters.push({
            type: dealAttribute.name,
            label: `${dealAttribute.name} only`,
            handler: () =>
              onChangeAttributes({ [dealAttribute.slug]: undefined }),
          });
        }
      }
    });

    return appliedFilters;
  }, [
    filters,
    profileParams,
    activeAutoCampaign,
    dealAttributes,
    onChange,
    onChangeAttributes,
  ]);

  return (
    <>
      {appliedFilters.length > 0 && (
        <div className="flex flex-wrap items-center justify-start gap-x-2.5">
          {appliedFilters.map((label) => (
            <AppliedFilter
              key={`${label.type}-${label.label}`}
              type={label.type}
              label={label.label}
              onClick={label.handler}
            />
          ))}
          <button className="mb-2.5 text-blue-600 underline" onClick={onClear}>
            Clear all
          </button>
        </div>
      )}
    </>
  );
}
