import { BrowseFilter } from '.';
import { BrowseFilterRadio } from '.';

const sorts = [
  { value: 'recommended', label: 'Recommended' },
  { value: 'latest', label: 'Latest' },
  { value: 'review_count', label: '# of customer ratings' },
  { value: 'rating', label: 'Avg customer rating' },
  { value: 'price_low', label: 'Price: Low to High' },
  { value: 'price_high', label: 'Price: High to Low' },
  { value: 'ending', label: 'Ending Soon' },
];

export function BrowseFilterMobileSort({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <BrowseFilter title="Sort by" className="sm:hidden">
      {sorts.map((sort) => (
        <BrowseFilterRadio
          key={sort.value}
          className="my-2"
          name="sort"
          value={sort.value}
          checked={value === sort.value}
          onChange={onChange}
        >
          {sort.label}
        </BrowseFilterRadio>
      ))}
    </BrowseFilter>
  );
}
