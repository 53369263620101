import { BrowseFilter } from '.';
import { BrowseFilterRadio } from '.';

const types = [
  { value: 'all', label: 'All' },
  { value: 'lifetime-deal', label: 'Lifetime deal' },
  { value: 'limited-license', label: 'Annual' },
  { value: 'freebie', label: 'Freebie' },
  { value: 'subscription', label: 'Subscription' },
  { value: 'digital-download', label: 'Digital download' },
];

/**
 * Deal plan type filter.
 */
export function BrowseFilterPlanTypes({
  value,
  onChange,
  onPrefetchChange = () => {},
}: {
  /**
   * Current value of filter.
   */
  value: string;
  /**
   * Callback for when the value is changed.
   */
  onChange: (value: string) => void;
  /**
   * Prefetch handler for when the value is changed.
   */
  onPrefetchChange?: (value: string) => void;
}) {
  return (
    <BrowseFilter title="Plan type">
      {types.map((type) => (
        <BrowseFilterRadio
          key={type.value}
          className="my-2"
          name="type"
          value={type.value}
          checked={value === type.value}
          onChange={onChange}
          onPrefetchChange={onPrefetchChange}
        >
          {type.label}
        </BrowseFilterRadio>
      ))}
    </BrowseFilter>
  );
}
