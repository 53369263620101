import { BrowseFilter } from '.';
import { BrowseFilterRadio } from '.';
import { useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { useMemo } from 'react';

const InputMoney = forwardRef<
  HTMLInputElement,
  {
    placeholder: string;
    value?: string;
    onChange: (value: string) => void;
  }
>(({ placeholder, value, onChange }, ref) => {
  return (
    <div className="flex rounded border border-gray-300">
      <span className="inline-flex items-center bg-gray-50 px-3 pr-0 text-sm text-gray-400">
        $
      </span>
      <input
        ref={ref}
        type="number"
        className="block w-full min-w-0 flex-1 rounded-none rounded-r-lg border border-none bg-gray-50 p-2.5 text-sm text-gray-900 focus:outline-none"
        placeholder={placeholder}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
});

InputMoney.displayName = 'InputMoney';

const debounce = (fn: (...args: any[]) => void, delay: number) => {
  let timerId: any = null;
  return (...args: any[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

type PriceType = {
  price_type?: string;
  price_from?: string;
  price_to?: string;
};

export function BrowseFilterPriceRange({
  value,
  onChange,
}: {
  value: PriceType;
  onChange: (value: PriceType) => void;
}) {
  const [localValue, setValue] = useState({ ...value });
  const priceFromEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value.price_type !== localValue.price_type) {
      console.log('Value changed');
    }
  }, [value.price_type, localValue.price_type]);

  // update filter with local values
  const emitChange = useCallback(
    (newValue: PriceType) => {
      onChange({
        price_type: undefined,
        price_from: newValue.price_from,
        price_to: newValue.price_to,
      });
    },
    [onChange],
  );

  const debouncedEmitChange = useMemo(
    () => debounce(emitChange, 100),
    [emitChange],
  );

  const onTypeChange = (price_type: string) => {
    setValue((prev) => {
      const newValue = {
        ...prev,
        price_type: price_type === 'custom' ? undefined : price_type,
      };
      if (price_type === 'free') {
        onChange({
          price_type: 'free',
          price_from: undefined,
          price_to: undefined,
        });
      } else {
        emitChange(newValue);
        priceFromEl?.current?.focus();
      }

      return newValue;
    });
  };

  const onPriceChange = (key: string, value: string) => {
    setValue((prev) => {
      const newValue = { ...prev, [key]: value };

      debouncedEmitChange(newValue);

      return newValue;
    });
  };

  return (
    <BrowseFilter title="Price range">
      <div className="flex flex-col">
        <BrowseFilterRadio
          className="my-2"
          name="price_range_free"
          value="free"
          checked={localValue.price_type === 'free'}
          onChange={onTypeChange}
        >
          Free
        </BrowseFilterRadio>
        <BrowseFilterRadio
          className="my-2"
          name="price_range_custom"
          value="custom"
          checked={localValue.price_type !== 'free'}
          onChange={onTypeChange}
        >
          Custom range
        </BrowseFilterRadio>
        <div
          className={`${
            value.price_type !== 'free' ? 'block' : 'hidden'
          } mt-2 flex items-center`}
        >
          <InputMoney
            ref={priceFromEl}
            placeholder="from"
            value={localValue.price_from}
            onChange={(value: string) => onPriceChange('price_from', value)}
          />
          <span className="mx-2 text-sm text-gray-400">to</span>
          <InputMoney
            placeholder="to"
            value={localValue.price_to}
            onChange={(value: string) => onPriceChange('price_to', value)}
          />
        </div>
      </div>
    </BrowseFilter>
  );
}
