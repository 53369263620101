import { Heading } from '@appsumo/dorado-react';
import { memo, useMemo } from 'react';

import AskSumoBox from '../discussions/AskSumoBox';
import { AlternativeTo, Attribute, TopFeatures } from './AlternativeTo';
import { DealSkuCardType } from './types';

function AlternativeToDeal({
  deal,
  enableVoting = false,
  mutate = () => {},
  votedDealId,
}: {
  deal: DealSkuCardType;
  enableVoting?: boolean;
  mutate?: () => void;
  votedDealId?: number;
}) {
  const alternativeTitle = useMemo(() => {
    let title = deal?.public_name;

    if (deal?.attributes?.best_for?.length) {
      title += ` - Best for ${deal.attributes.best_for[0]}`;
    }

    return title;
  }, [deal?.attributes?.best_for, deal?.public_name]);

  return (
    <div className="mb-8 flex flex-col gap-6 lg:max-w-screen-2xl lg:flex-row">
      <div className="grow space-y-4">
        {/* Using scroll-margin-top since user can scroll by using table of content
         and we have a fixed navbar header. So we tell the browser to offset 
         the position of the missing height navbar)
        */}
        <Heading.H3
          id={deal?.slug}
          className="scroll-mt-[165px] !text-2xl lg:scroll-mt-[145px]"
        >
          {alternativeTitle}
        </Heading.H3>

        <AlternativeTo
          deal={deal}
          enableVoting={enableVoting}
          mutate={mutate}
          votedDealId={votedDealId}
          showOnlyMainInfo
        />

        {deal?.reviews_summary && (
          <div>
            <Heading.H4
              id={`what_users_say_${deal?.slug}`}
              className="scroll-mt-[165px] lg:scroll-mt-[145px]"
            >
              What do users say about {deal?.public_name}
            </Heading.H4>

            <AskSumoBox summary={deal.reviews_summary} />
          </div>
        )}

        {!!deal.common_features.length && (
          <div className="space-y-2">
            <Heading.H4
              id={`top_features_${deal?.slug}`}
              className="scroll-mt-[165px] lg:scroll-mt-[145px]"
            >
              Top features
            </Heading.H4>

            <TopFeatures features={deal.common_features} />
          </div>
        )}

        {(!!deal?.attributes?.best_for?.length ||
          !!deal?.attributes?.integrations?.length) && (
          <div className="space-y-2">
            {!!deal?.attributes?.best_for?.length && (
              <Attribute
                label="Best for"
                attribute={deal?.attributes?.best_for}
              />
            )}

            {!!deal?.attributes?.integrations?.length && (
              <Attribute
                label="Integrations"
                attribute={deal?.attributes?.integrations}
              />
            )}
          </div>
        )}

        {deal?.snippet_seo && (
          <div className="space-y-2">
            <Heading.H4
              id={`what_is_${deal?.slug}`}
              className="scroll-mt-[165px] text-xl lg:scroll-mt-[145px]"
            >
              What is {deal?.public_name}?
            </Heading.H4>

            <div
              className="space-y-2 break-words md:grow"
              dangerouslySetInnerHTML={{ __html: deal?.snippet_seo }}
            />
          </div>
        )}
      </div>
      <div className="hidden w-full max-w-sm shrink-0 md:block"></div>
    </div>
  );
}

export default memo(AlternativeToDeal);
