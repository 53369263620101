import Image from 'next/image';
import { Button } from '@appsumo/dorado-react';

import { useBannerType } from '~/lib/browse/hooks';
import { Link } from '~/components/global/Link';
import {
  DealImage,
  DealPrice,
  DealRating,
  DealDownloads,
} from '~/components/product';
import staffPick from '~/public/staff-pick.svg';
import { SkuBadge } from './sku-components/SkuBadge';
import { SkuCategory } from './sku-components/SkuCategory';
import { DealSkuCardType } from './types';

/**
 * Default deal SKU card for browse and carousels.
 */
export function BestMatch({
  deal,
  target = '_self',
}: {
  /**
   * Deal object (JSON) from the esbrowse endpoint
   */
  deal: DealSkuCardType;
  /**
   * Target for the link
   * @default '_self'
   */
  target?: '_self' | '_blank';
}) {
  const bannerType = useBannerType(deal) ?? '';
  const showPricing = !['coming_soon', 'sold_out'].includes(bannerType);

  return (
    <div className="relative flex flex-col border border-lime shadow-md sm:flex-row md:items-center">
      <div className="flex h-full w-full shrink grow-0 flex-col sm:max-w-[250px] lg:max-w-[450px] lg:gap-y-2">
        <div className="flex h-[32px] items-center justify-center gap-x-2  bg-[#6ad989] p-2 align-middle text-sm  lg:absolute lg:right-4 lg:top-4 lg:rounded">
          <Image src={staffPick} alt="Staff Pick" width={24} height={24} />
          <strong className="align-middle">Best match</strong>
        </div>
        <div className="relative h-full w-full rounded">
          <Link
            href={`${deal.get_absolute_url}`}
            target={target}
            className="absolute h-full w-full text-[0px] after:pointer-events-auto after:absolute after:inset-0 after:z-[1] after:content-['']"
          >
            <span className="sr-only">{deal.public_name}</span>
          </Link>
          <DealImage
            deal={deal}
            priority={true}
            width={450}
            height={300}
            className="!rounded-none"
          />
        </div>
      </div>
      <div className="flex grow flex-col gap-y-2 p-2 lg:gap-y-2 lg:p-4">
        <SkuBadge deal={deal} className="order-last lg:order-first" />
        <div className="flex flex-col">
          <Link
            href={`${deal.get_absolute_url}`}
            target={target}
            className="pb-1 text-xs font-bold hover:underline lg:mr-[136px] lg:text-2xl"
          >
            <span className="inline-block line-clamp-2">
              {deal.public_name}
            </span>
          </Link>
          <SkuCategory deal={deal} className="text-xs lg:hidden" />
        </div>

        <div className="md:grow">
          <div
            className="my-1 text-left line-clamp-2 max-lg:text-xs"
            dangerouslySetInnerHTML={{ __html: deal.card_description }}
          />
        </div>
        {showPricing && (
          <div className="text-sm font-medium lg:text-2xl">
            <DealPrice deal={deal} />
          </div>
        )}
        <div className="flex items-center gap-x-4">
          <Button
            href={`${deal.get_absolute_url}`}
            className="whitespace-nowrap max-lg:hidden"
          >
            View details
          </Button>
          <div>
            {deal.is_free && (
              <DealDownloads
                downloads={deal.freebie_downloads_total}
                href={`${deal.get_absolute_url}`}
                target={target}
                className="justify-start md:justify-center"
              />
            )}
            {!deal.is_free && (
              <DealRating
                averageRating={deal.deal_review?.average_rating}
                reviewCount={deal.deal_review?.review_count}
                className="mb-2 justify-start md:justify-center"
                href={`${deal.get_absolute_url}#reviews`}
                target={target}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
