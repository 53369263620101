import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { SkuCard } from './SkuCard';
import { DealSkuCardType } from './types';

export const SaveForLater = ({
  deal,
  deleteCallback,
}: {
  deal: DealSkuCardType;
  deleteCallback?: (dealId: number) => void;
}): React.ReactElement => {
  return (
    <div className="relative h-full w-full">
      <button
        className="absolute right-0 z-2 m-2 h-10 w-10 rounded-full border border-gravel bg-white px-3 py-2"
        onClick={() => deleteCallback && deleteCallback(deal.id)}
      >
        <FontAwesomeIcon
          icon={faTrash}
          height="16"
          className="text-lg text-brick"
        />
      </button>
      <SkuCard deal={deal} />
    </div>
  );
};
