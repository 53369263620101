import React, { useId, ReactNode, useCallback } from 'react';

/**
 * Checkbox browse filter.
 */
export function BrowseFilterCheckbox({
  onChange,
  onPrefetchChange = () => {},
  name,
  checked,
  children,
  className = '',
}: {
  /**
   * Name of the form input.
   */
  name: string;
  /**
   * Checked state of the checkbox.
   */
  checked: boolean;
  /**
   * Callback for when the checkbox is toggled.
   */
  onChange: (value: boolean) => void;
  /**
   * Prefetch handler for when the checkbox is toggled.
   */
  onPrefetchChange?: (value: boolean) => void;
  /**
   * Additional class names to apply to the root element.
   */
  className?: string;
  children: ReactNode;
}) {
  const id = `browse-filter-${useId()}`;

  const onMouseEnter = useCallback(() => {
    onPrefetchChange(!checked);
  }, [checked, onPrefetchChange]);

  return (
    <div
      className={`flex items-center ${className}`}
      onMouseEnter={onMouseEnter}
      data-testid="browse-filter-checkbox"
    >
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="h-6 w-6 cursor-pointer rounded border-black"
      />
      <label
        htmlFor={id}
        className="ml-2 flex w-full cursor-pointer items-center font-header font-semibold"
      >
        {children}
      </label>
    </div>
  );
}
