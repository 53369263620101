import { useMemo, useEffect, useContext } from 'react';
import useSWR from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Transition } from '@headlessui/react';

import { GlobalContext } from '~/contexts/global';
import { AskSumoBrowse } from '~/components/browse/AskSumoBrowse';
import {
  BrowseFilterAttributeBoolean,
  BrowseFilterAttributeEnumerationMulti,
  BrowseFilterAttributeEnumerationSingle,
  BrowseFilterCampaign,
  BrowseFilterCategories,
  BrowseFilterMobileSort,
  BrowseFilterPlanTypes,
  BrowseFilterPriceRange,
  BrowseFilterSelect,
  BrowseFilterStatus,
} from '~/components/browse/filters';
import type {
  EsBrowseAggregations,
  EsBrowseAttributeAggregation,
  EsBrowseData,
} from '~/components/browse/types';
import { Button } from '~/components/ui';
import { useLayout } from '~/layouts';
import checkDependency from '~/lib/attributes/deps';
import { getEsBrowseUrl } from '~/lib/browse/fetch';

type SortedFilter = {
  type: string;
  priority: number;
  exclude?: string;
  attribute?: EsBrowseAttributeAggregation;
};

/**
 * Side bar and mobile modal for all filters including categories, built-in and deal attributes.
 */
export function BrowseFilters({
  filters,
  profileParams = {},
  aggregations,
  fullWidth = false,
  onChange,
  onPrefetchChange,
  open = false,
  onClose,
  onChangeAttributes,
  onPrefetchChangeAttributes,
  onApplyMobileFilters,
  onResetMobileFilters,
  onPrefetchMobileFilters,
}: {
  filters: any;
  profileParams: {};
  aggregations: EsBrowseAggregations;
  fullWidth: boolean;
  onChange: (key: string | {}, value?: string | number | boolean) => void;
  onPrefetchChange: (
    key: string | {},
    value?: string | number | boolean,
  ) => void;
  open: boolean;
  onClose: () => void;
  onChangeAttributes: (attributes: {}) => void;
  onPrefetchChangeAttributes: (attributes: {}) => void;
  onApplyMobileFilters: () => void;
  onResetMobileFilters: () => void;
  onPrefetchMobileFilters: () => void;
}) {
  const isSearching = !!filters.query;
  const { setCanScrollBody } = useLayout();
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);
  const { attributes = {} } = filters;
  // if mobile is open use SWR to fetch mobile document counts
  const url = getEsBrowseUrl(filters, profileParams, 1);
  const { data, isValidating } = useSWR<EsBrowseData>(open ? url : null);
  const { meta = null } = data || {};

  useEffect(() => {
    setCanScrollBody(open !== true);
  }, [open, setCanScrollBody]);

  // build our array of filter components to render
  const filtersToRender = useMemo(() => {
    let sortedFilters: SortedFilter[] = [
      {
        type: 'sort',
        priority: Number.MAX_VALUE,
        exclude: 'sort',
      },
      {
        type: 'select',
        priority: 9e3,
        exclude: 'marketplace',
      },
      {
        type: 'campaign',
        priority: 8e3,
        exclude: 'campaign',
      },
      {
        type: 'plan_types',
        priority: 5e3,
        exclude: 'type',
      },
      {
        type: 'status',
        priority: 4e3,
        exclude: 'status',
      },
      {
        type: 'price_range',
        priority: 3e3,
        exclude: 'default_price',
      },
    ];

    const defaultFilters = Object.keys(profileParams);

    // append deal attributes to the filters array
    aggregations?.attributes?.forEach((attr) => {
      // skip our taxonomy attributes or any attributes with dependencies not being satisfied
      if (
        ['group', 'category', 'subcategory'].includes(attr.slug) ||
        !checkDependency(attr.dependency, attributes)
      )
        return;

      sortedFilters.push({
        type: 'attribute',
        priority: attr.weight,
        attribute: attr,
      });
    });

    sortedFilters = sortedFilters.filter(
      (filter) => !filter.exclude || !defaultFilters.includes(filter.exclude),
    );

    // sort by priority
    sortedFilters.sort((a, b) => b.priority - a.priority);

    // determine list of components to render based on the sorted list
    const sidebarFilters: any[] = [];
    sortedFilters.forEach((filter) => {
      switch (filter.type) {
        case 'sort':
          sidebarFilters.push(
            <BrowseFilterMobileSort
              key={filter.type}
              value={filters.sort || 'recommended'}
              onChange={(value) => onChange('sort', value)}
            />,
          );
          break;

        case 'select':
          sidebarFilters.push(
            <BrowseFilterSelect
              key={filter.type}
              name={filter.type}
              checked={String(filters.marketplace).toLowerCase() === 'false'}
              onChange={(value) =>
                onChange('marketplace', value ? false : undefined)
              }
              onPrefetchChange={(value) =>
                onPrefetchChange('marketplace', value ? false : undefined)
              }
            />,
          );
          break;

        case 'campaign':
          if (activeAutoCampaign?.campaign_config?.can_use_browse_filter) {
            sidebarFilters.push(
              <BrowseFilterCampaign
                key={filter.type}
                name={filter.type}
                checked={
                  String(filters.campaign).toLowerCase() ===
                  activeAutoCampaign.optin_slug
                }
                onChange={(value) =>
                  onChange(
                    'campaign',
                    value ? activeAutoCampaign.optin_slug : undefined,
                  )
                }
                onPrefetchChange={(value) =>
                  onPrefetchChange(
                    'campaign',
                    value ? activeAutoCampaign.optin_slug : undefined,
                  )
                }
              />,
            );
          }
          break;

        case 'plan_types':
          sidebarFilters.push(
            <BrowseFilterPlanTypes
              key={filter.type}
              value={filters.type || 'lifetime-deal'}
              onChange={(value) => onChange('type', value)}
              onPrefetchChange={(value) => onPrefetchChange('type', value)}
            />,
          );
          break;

        case 'status':
          sidebarFilters.push(
            <BrowseFilterStatus
              key={filter.type}
              value={filters.status || (isSearching ? 'all' : 'current')}
              onChange={(value) => onChange('status', value)}
              onPrefetchChange={(value) => onPrefetchChange('status', value)}
            />,
          );
          break;

        case 'price_range':
          sidebarFilters.push(
            <BrowseFilterPriceRange
              key={filter.type}
              value={{
                price_type: filters.price_type,
                price_from: filters.price_from,
                price_to: filters.price_to,
              }}
              onChange={(price) => onChange(price)}
            />,
          );
          break;

        case 'attribute':
          {
            const attr = filter.attribute;

            if (attr?.type === 'boolean') {
              sidebarFilters.push(
                <BrowseFilterAttributeBoolean
                  key={attr.slug}
                  name={attr.slug}
                  label={attr.name}
                  checked={!!attributes[attr.slug]}
                  onChange={(value: boolean) =>
                    onChangeAttributes({
                      [attr.slug]: value ? 'true' : undefined,
                    })
                  }
                />,
              );
            } else if (attr?.type === 'enumeration') {
              if (attr.type_options?.multiple === true) {
                sidebarFilters.push(
                  <BrowseFilterAttributeEnumerationMulti
                    key={attr.slug}
                    name={attr.slug}
                    title={attr.name}
                    options={attr.type_options?.options}
                    values={attributes[attr.slug] || []}
                    filters={attributes}
                    initialResults={attr.type_options?.initial_results}
                    maximumResults={attr.type_options?.maximum_results}
                    onChange={(values) =>
                      onChangeAttributes({ [attr.slug]: values })
                    }
                    onPrefetchChange={(values) =>
                      onPrefetchChangeAttributes({ [attr.slug]: values })
                    }
                  />,
                );
              } else {
                sidebarFilters.push(
                  <BrowseFilterAttributeEnumerationSingle
                    key={attr.slug}
                    name={attr.slug}
                    title={attr.name}
                    options={attr.type_options?.options}
                    value={attributes[attr.slug]}
                    filters={attributes}
                    initialResults={attr.type_options?.initial_results}
                    maximumResults={attr.type_options?.maximum_results}
                    onChange={(value) =>
                      onChangeAttributes({ [attr.slug]: value })
                    }
                    onPrefetchChange={(value) =>
                      onPrefetchChangeAttributes({ [attr.slug]: value })
                    }
                  />,
                );
              }
            }
          }
          break;
      }
    });

    return sidebarFilters;
  }, [
    activeAutoCampaign,
    aggregations,
    attributes,
    profileParams,
    onChange,
    onPrefetchChange,
    onChangeAttributes,
    onPrefetchChangeAttributes,
    filters,
    isSearching,
  ]);

  const isCollection: boolean = !!attributes.collectionSlug;

  const baseClasses = 'bg-white';
  const mobileClasses =
    'max-md:fixed max-md:top-0 max-md:bottom-0 max-md:z-[9999] max-md:flex max-md:w-full max-md:flex-col max-md:shadow';
  const fullClasses =
    'md:fixed md:top-0 md:bottom-0 md:z-[9999] md:flex md:w-full md:flex-col md:shadow md:max-w-sm';

  return (
    <Transition
      show={open}
      as="aside"
      appear
      unmount={false}
      className={fullWidth ? '' : 'md:!block'}
    >
      <Transition.Child
        as="div"
        unmount={false}
        enter="transition-filters duration-300"
        enterFrom="max-md:-left-[100%] md:-right-[100%]"
        enterTo="max-md:left-0 md:right-0"
        leave="transition-filters duration-300"
        leaveFrom="max-md:left-0 md:right-0"
        leaveTo="max-md:-left-[100%] md:-right-[100%]"
        className={`${baseClasses} ${mobileClasses} ${
          fullWidth ? fullClasses : 'md:!block'
        }`}
      >
        <div className={`relative shrink p-4 ${fullWidth ? '' : 'md:hidden'}`}>
          <h5 className="mt-4 font-header text-2xl font-semibold">Filters</h5>
          <button className="absolute right-5 top-5" onClick={onClose}>
            <span className="sr-only">Close Filters</span>
            <FontAwesomeIcon icon={faTimes} className="text-xl" />
          </button>
        </div>
        <div
          className={`flex flex-col ${
            fullWidth
              ? 'grow overflow-y-scroll p-4'
              : 'max-md:grow max-md:overflow-y-scroll max-md:p-4'
          }`}
        >
          <BrowseFilterCategories
            isCollection={isCollection}
            currentGroup={attributes.group}
            currentCategory={attributes.category}
            currentSubcategory={attributes.subcategory}
            query={filters.query}
            aggregations={aggregations}
            onChange={(attributes) => onChangeAttributes(attributes)}
          />
          {filtersToRender.map((filter) => filter)}
        </div>
        <div
          className={`flex justify-between p-4 shadow-[0_-2px_4px_0px_rgb(0,0,0,0.1)] ${
            fullWidth ? '' : 'md:hidden'
          }`}
        >
          <Button
            tertiary
            disabled={isValidating}
            onClick={onResetMobileFilters}
          >
            Clear filters
          </Button>
          <Button
            disabled={isValidating || meta?.total_results === 0}
            onClick={onApplyMobileFilters}
            onMouseEnter={() => onPrefetchMobileFilters()}
            onTouchStart={() => onPrefetchMobileFilters()}
          >
            Apply
            {meta && <span className="ml-1">({meta.total_results})</span>}
            {!meta && <span className="ml-1">(...)</span>}
          </Button>
        </div>
        <AskSumoBrowse className="hidden md:flex" />
      </Transition.Child>
      <Transition.Child
        as="div"
        unmount={false}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-50"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-50"
        leaveTo="opacity-0"
        className="fixed inset-0 z-40 bg-black"
        onClick={onClose}
      ></Transition.Child>
    </Transition>
  );
}
