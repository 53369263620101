import { BrowseFilter } from '.';
import { BrowseFilterRadio } from '.';

const statuses = [
  { value: 'all', label: 'All' },
  { value: 'current', label: 'Live deals' },
  { value: 'ending', label: 'Ending soon' },
  { value: 'expired', label: 'Unavailable' },
];

/**
 * Deal status filter.
 */
export function BrowseFilterStatus({
  value,
  onChange,
  onPrefetchChange = () => {},
}: {
  /**
   * Current value of filter.
   */
  value: string;
  /**
   * Callback for when the value is changed.
   */
  onChange: (value: string) => void;
  /**
   * Prefetch handler for when the value is changed.
   */
  onPrefetchChange?: (value: string) => void;
}) {
  return (
    <BrowseFilter title="Status">
      {statuses.map((status) => (
        <BrowseFilterRadio
          key={status.value}
          className="my-2"
          name="status"
          value={status.value}
          checked={value === status.value}
          onChange={onChange}
          onPrefetchChange={onPrefetchChange}
        >
          {status.label}
        </BrowseFilterRadio>
      ))}
    </BrowseFilter>
  );
}
