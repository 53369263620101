import { useMemo } from 'react';
import { Breadcrumbs } from '~/components/ui';
import useAttributes from '~/lib/attributes/hook';

/**
 * Breadcrumbs for the specified browse taxonomy.
 */
export function BrowseBreadcrumbs({
  group,
  category,
  subcategory,
}: {
  /**
   * Current group slug.
   */
  group?: string;
  /**
   * Current category slug.
   */
  category?: string;
  /**
   * Current subcategory slug.
   */
  subcategory?: string;
}) {
  const { data } = useAttributes();

  const items = useMemo(() => {
    // TODO: refactor these from use hook
    const ret = [];
    const groupAttr = data?.find((attr: any) => attr.slug === 'group');
    const categoryAttr = data?.find((attr: any) => attr.slug === 'category');
    const subcategoryAttr = data?.find(
      (attr: any) => attr.slug === 'subcategory',
    );

    const groupOpt = groupAttr?.type_options?.options.find(
      (opt: any) => opt.value === group,
    );
    const categoryOpt = categoryAttr?.type_options?.options.find(
      (opt: any) => opt.value === category,
    );
    const subcategoryOpt = subcategoryAttr?.type_options?.options.find(
      (opt: any) => opt.value === subcategory,
    );

    if (groupOpt) {
      ret.push({ href: `/${groupOpt.value}/`, label: groupOpt.name });

      if (categoryOpt) {
        ret.push({
          href: `/${groupOpt.value}/${categoryOpt.value}/`,
          label: categoryOpt.name,
        });

        if (subcategoryOpt) {
          ret.push({
            href: `/${groupOpt.value}/${categoryOpt.value}/${subcategoryOpt.value}/`,
            label: subcategoryOpt.name,
          });
        }
      }
    }

    return ret;
  }, [data, group, category, subcategory]);

  return <Breadcrumbs items={items} className="mb-6" />;
}
