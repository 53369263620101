import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Link } from '~/components/global/Link';
import { BrowseFilter } from '.';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAttributes from '~/lib/attributes/hook';
import checkDependency from '~/lib/attributes/deps';
import type { DealAttributeOption } from '../types';
import clsx from 'clsx';
import { AskSumoBrowse } from '../AskSumoBrowse';

type DealAttribute = {
  id: number;
  name: string;
  slug: string;
  type: string;
  type_options: {
    options: DealAttributeOption[];
    multiple?: boolean;
  };
  dependency: any;
  weight: number;
};

type TaxonomyAttributes = {
  group?: string;
  category?: string;
  subcategory?: string;
};

interface BackButtonProps {
  onClick: (
    e: React.SyntheticEvent<EventTarget>,
    attributes: TaxonomyAttributes,
  ) => void;
  group?: string;
  category?: string;
  subcategory?: string;
  name?: string;
  query?: string;
  href: string;
}

function BackButton({
  onClick,
  group,
  category,
  subcategory,
  name,
  href,
}: BackButtonProps) {
  return (
    <Link
      onClick={(e) =>
        onClick(e, {
          group,
          category,
          subcategory,
        })
      }
      href={href}
      className="mb-5 flex items-center border-b pb-2"
    >
      <FontAwesomeIcon icon={faChevronLeft} height="10" className="mr-2" />
      <span className="font-header text-sm font-semibold">
        Back to all {name}
      </span>
    </Link>
  );
}
type BrowseFilterHrefAttributes = {
  [index: string]: {
    group?: string;
    category?: string;
    subcategory?: string;
  };
};

export function BrowseFilterCategories({
  isCollection = false,
  currentGroup = undefined,
  currentCategory = undefined,
  currentSubcategory = undefined,
  query = undefined,
  aggregations,
  onChange,
}: {
  isCollection?: boolean;
  currentGroup?: string;
  currentCategory?: string;
  currentSubcategory?: string;
  query?: string;
  aggregations: any;
  onChange: (attributes: TaxonomyAttributes) => void;
}) {
  const isSearching = !!query;
  const router = useRouter();
  const currentPath = router.asPath.split('?')[0]; // remove query params
  const { data } = useAttributes();
  // TODO -- Find out why (or fix) aggregations.attributes is undefined
  const attributes = isSearching ? aggregations?.attributes || [] : data;
  const group = attributes?.find(
    (attr: DealAttribute) => attr.slug === 'group',
  );
  const category = attributes?.find(
    (attr: DealAttribute) => attr.slug === 'category',
  );
  const subcategory = attributes?.find(
    (attr: DealAttribute) => attr.slug === 'subcategory',
  );

  // current values for dependency checking
  const values = useMemo(
    () => ({
      group: currentGroup,
      category: currentCategory,
      subcategory: currentSubcategory,
    }),
    [currentGroup, currentCategory, currentSubcategory],
  );

  // list of categories to render
  const categories = useMemo(
    () =>
      category?.type_options?.options.filter((option: DealAttributeOption) => {
        return checkDependency(option.dependency, values);
      }) ?? [],
    [category, values],
  );

  // list of subcategories to render
  const subcategories = useMemo(
    () =>
      subcategory?.type_options?.options.filter(
        (option: DealAttributeOption) => {
          return checkDependency(option.dependency, values);
        },
      ) ?? [],
    [subcategory, values],
  );

  // current group name for back to...
  const currentGroupName = useMemo(
    () =>
      group?.type_options?.options.find(
        (option: DealAttributeOption) => option.value === currentGroup,
      )?.name,
    [group, currentGroup],
  );

  // on click handler for mobile navigation
  const onClick = useCallback(
    (e: React.SyntheticEvent<EventTarget>, attributes: TaxonomyAttributes) => {
      e.preventDefault();

      onChange(attributes);
    },
    [onChange],
  );

  const linkHref = useCallback(
    (attributes: BrowseFilterHrefAttributes) => {
      if (isCollection) {
        const query = Object.keys(attributes)
          .map((attrKey) => `${attrKey}=${attributes[attrKey]}`)
          .join('&');
        return [currentPath, query].join('?');
      }
      return `/${Object.values(attributes).join('/')}`;
    },
    [isCollection, currentPath],
  );

  const backButtonQueryParams: string = query ? `?${query}` : '';
  const backButtonHref: string = isCollection
    ? `${currentPath}`
    : `/${currentGroup || 'search'}/${backButtonQueryParams}`;

  // temp hack for  'nav-project-fomo-v2' experiment
  // Our goal is to match the behavior of the /search path when on experimental /courses-more path
  // Basically dont want our taxonomy to think courses-more is a 'group', so we can ensure filtering
  // works once we choose group from /courses-more path.
  // manually setting currentGroup = undefined to match /search behavior
  if (currentGroup === 'courses-more' && currentPath === '/courses-more/') {
    currentGroup = undefined;
  }

  return (
    <BrowseFilter>
      <AskSumoBrowse className="md:hidden" />

      {(isSearching && currentGroup) ||
      (currentGroup && currentCategory) ||
      (isCollection && currentGroup) ? (
        <BackButton
          onClick={onClick}
          group={
            (isSearching && !currentCategory) || isCollection
              ? undefined
              : currentGroup
          }
          category={undefined}
          subcategory={undefined}
          name={
            currentCategory
              ? currentGroupName
              : (isSearching && currentGroup) || isCollection
              ? 'results'
              : currentGroupName
          }
          href={backButtonHref}
          query={query}
        />
      ) : (
        ''
      )}
      <h5 className="pb-2 font-header font-semibold">
        Shop by{currentGroup && ' category'}:
      </h5>
      <ul>
        {group?.type_options?.options.map((group: DealAttributeOption) => (
          <li key={group.value} className="my-1">
            <Link
              onClick={(e) =>
                onClick(e, {
                  group: group.value,
                  category: undefined,
                  subcategory: undefined,
                })
              }
              href={linkHref({
                group: group.value,
              } as BrowseFilterHrefAttributes)}
              className={clsx(
                'block',
                { 'font-bold underline': group.value === currentGroup },
                currentGroup === undefined &&
                  // temp hack for  'nav-project-fomo-v2' experiment
                  !(
                    currentPath === '/courses-more/' &&
                    group.value === 'software'
                  )
                  ? ''
                  : 'hidden',
              )}
            >
              {group.name}
            </Link>
            {group.value === currentGroup && (
              <ul className={`${currentGroup === undefined ? 'pl-4' : ''}`}>
                {categories.map((category: DealAttributeOption) => (
                  <li key={category.value} className="my-1">
                    <Link
                      onClick={(e) =>
                        onClick(e, {
                          group: group.value,
                          category: category.value,
                          subcategory: undefined,
                        })
                      }
                      href={linkHref({
                        group: group.value,
                        category: category.value,
                      } as BrowseFilterHrefAttributes)}
                      className={`block ${
                        currentSubcategory === undefined &&
                        category.value === currentCategory
                          ? 'font-bold underline'
                          : ''
                      }`}
                    >
                      {category.name}
                    </Link>
                    {category.value === currentCategory && (
                      <ul className="pl-4">
                        {subcategories.map(
                          (subcategory: DealAttributeOption) => (
                            <li key={subcategory.value}>
                              <Link
                                onClick={(e) =>
                                  onClick(e, {
                                    group: group.value,
                                    category: category.value,
                                    subcategory: subcategory.value,
                                  })
                                }
                                href={linkHref({
                                  group: group.value,
                                  category: category.value,
                                  subcategory: subcategory.value,
                                } as BrowseFilterHrefAttributes)}
                                className={`block ${
                                  subcategory.value === currentSubcategory
                                    ? 'font-bold underline'
                                    : ''
                                }`}
                              >
                                {subcategory.name}
                              </Link>
                            </li>
                          ),
                        )}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </BrowseFilter>
  );
}
