import { memo, useMemo } from 'react';

import useAttributes from '~/lib/attributes/hook';
import { taxonomyTitle } from '~/lib/format';

/**
 * Display a title for the current browse taxonomy.
 */
export const BrowseTitle = memo(
  ({
    group,
    category,
    subcategory,
    query,
    numberResults,
  }: {
    /**
     * Current group slug.
     */
    group?: string;
    /**
     * Current category slug.
     */
    category?: string;
    /**
     * Current subcategory slug.
     */
    subcategory?: string;
    /**
     * Search query.
     */
    query?: string;
    /**
     * Number of results.
     */
    numberResults: number;
  }) => {
    const { data } = useAttributes();

    const title = useMemo(() => {
      if (query) {
        if (numberResults) {
          return `Products matching "${query}"`;
        } else {
          return `Sorry, we couldn't find any results matching "${query}"`;
        }
      }

      // TODO: refactor these from use hook
      const groupAttr = data?.find((attr: any) => attr.slug === 'group');
      const categoryAttr = data?.find((attr: any) => attr.slug === 'category');
      const subcategoryAttr = data?.find(
        (attr: any) => attr.slug === 'subcategory',
      );

      const groupOpt = groupAttr?.type_options?.options.find(
        (opt: any) => opt.value === group,
      );
      const categoryOpt = categoryAttr?.type_options?.options.find(
        (opt: any) => opt.value === category,
      );
      const subcategoryOpt = subcategoryAttr?.type_options?.options.find(
        (opt: any) => opt.value === subcategory,
      );

      if (groupOpt) {
        if (subcategoryOpt) {
          return taxonomyTitle(groupOpt.name, subcategoryOpt.name);
        } else if (categoryOpt) {
          return taxonomyTitle(groupOpt.name, categoryOpt.name);
        }

        return `Browse ${groupOpt.name.toLowerCase()}`;
      }

      if (group === 'courses-more') return 'Browse courses & more';

      return 'Browse products';
    }, [data, group, category, subcategory, query, numberResults]);

    return <h1 className="mb-5 font-header text-2xl font-bold">{title}</h1>;
  },
);

BrowseTitle.displayName = 'BrowseTitle';
