import { Dropdown } from '@appsumo/dorado-react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import {
  createElement,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { InView } from 'react-intersection-observer';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { BrowseBreadcrumbs, BrowseTitle } from '~/components/browse';
import type { DealSkuCardType } from '~/components/sku';
import { BestMatch, SkuCard } from '~/components/sku';
import AlternativeToDeal from '~/components/sku/AlternativeToDeal';
import { Loader } from '~/components/ui';
import { SmartCollectionContext } from '~/contexts/smart-collection';
import { DEALATTRIBUTE_PERSONAL_URL } from '~/lib/attributes/constants';
import { useSavedCart } from '~/lib/cart/useCart';
import { useExperiment } from '~/lib/experiment';
import { fetchJson } from '~/lib/fetch';
import { thousands } from '~/lib/format';
import { useOneTap } from '~/lib/script';
import { replaceURLParam } from '~/lib/url';
import useUser from '~/lib/user';
import { LTD_QUANTITY_SKU_EXPERIMENT_NAME } from '~/lib/util/constants';
import { isExperimentalVariant } from '~/lib/util/helpers';
import { getEsBrowseUrl } from '../../lib/browse/fetch';
import { Roadblock } from '../sku/Roadblock';
import { SaveForLater } from '../sku/SaveForLater';
import { BrowseAppliedFilters } from './filters';
import { BrowseFilters } from './filters/';
import {
  BrowseLayoutTypes,
  DealAttribute,
  EsBrowseData,
  EsBrowseMeta,
  FilterAction,
  FilterState,
  SkuCardTypes,
} from './types';

const infiniteScrollPages = 3;
const SKU_CARD_ALTERNATIVE_NAME = 'alternative';

function filtersToUrl(
  filters: FilterState,
  dealAttributes: DealAttribute[] = [],
) {
  const { attributes = {} } = filters;
  const searchParams = new URLSearchParams(window.location.search);
  const basePath = [];

  if (attributes.collectionSlug) {
    basePath.push('collections');
    basePath.push(attributes.collectionSlug);

    if (attributes.templateSlug) {
      basePath.push(attributes.templateSlug);
    }

    // If in collection, map over product categories
    ['category', 'subcategory', 'group'].forEach((attr) => {
      if (attributes[attr]) {
        searchParams.set(attr, attributes[attr]);
      } else {
        searchParams.delete(attr);
      }
    });
  } else if (attributes.group) {
    if (attributes.group) {
      basePath.push(attributes.group);
    }

    if (attributes.category) {
      basePath.push(attributes.category);
    }

    if (attributes.subcategory) {
      basePath.push(attributes.subcategory);
    }
  } else if (filters.query) {
    basePath.push('search');
  } else {
    basePath.push('browse');
  }

  if (filters.sort) {
    searchParams.set('sort', filters.sort);
  } else {
    searchParams.delete('sort');
  }

  if (String(filters.marketplace) === 'false') {
    searchParams.set('select', 'true');
  } else {
    searchParams.delete('select');
  }

  if (filters.campaign) {
    searchParams.set('campaign', filters.campaign);
  } else {
    searchParams.delete('campaign');
  }

  if (filters.status) {
    searchParams.set('status', filters.status);
  } else {
    searchParams.delete('status');
  }

  if (filters.type) {
    searchParams.set('type', filters.type);
  } else {
    searchParams.delete('type');
  }

  if (filters.price_type === 'free') {
    searchParams.set('price_type', 'free');
    searchParams.delete('price_from');
    searchParams.delete('price_to');
  } else {
    searchParams.delete('price_type');
    searchParams.delete('price_from');
    searchParams.delete('price_to');

    if (typeof filters.price_from !== 'undefined') {
      searchParams.set('price_from', String(filters.price_from));
    }

    if (typeof filters.price_to !== 'undefined') {
      searchParams.set('price_to', String(filters.price_to));
    }
  }

  // check status of deal attributes and update URL parameters
  dealAttributes.forEach((dealAttribute) => {
    if (['category', 'subcategory', 'group'].includes(dealAttribute.slug))
      return;

    const value = attributes[dealAttribute.slug];

    if (typeof value === 'undefined') {
      searchParams.delete(dealAttribute.slug);
    } else {
      if (dealAttribute.type === 'enumeration') {
        if (dealAttribute.type_options?.multiple === true) {
          if (value.length > 0) {
            searchParams.set(dealAttribute.slug, value.join(','));
          } else {
            searchParams.delete(dealAttribute.slug);
          }
        } else {
          if (value) {
            searchParams.set(dealAttribute.slug, value);
          } else {
            searchParams.delete(dealAttribute.slug);
          }
        }
      } else {
        searchParams.set(dealAttribute.slug, value);
      }
    }
  });

  if (filters.query) {
    searchParams.set('query', filters.query);
  } else {
    searchParams.delete(filters.query as string);
  }

  const search = searchParams.toString();

  return `/${basePath.join('/')}/${search ? `?${search}` : ''}`;
}

function filterReducer(state: FilterState, action: FilterAction): FilterState {
  switch (action.type) {
    // apply the changed filters
    case 'change':
      return {
        ...state,
        ...(action.changes ? action.changes : {}),
      };

    // apply the changes attributes
    case 'change_attributes':
      return {
        ...state,
        attributes: {
          ...(state.attributes ? state.attributes : {}),
          ...(action.attributes ? action.attributes : {}),
        },
      };

    // reset the state to the initial state
    case 'reset':
      return action.state!;

    // apply the filter to the new URL
    case 'apply':
      {
        const { router, dealAttributes } = action;
        const url = filtersToUrl(state, dealAttributes);
        // trigger a manual instant URL update as router does it after server
        window.history.pushState({}, '', url);
        router?.replace(url);
      }
      return state;

    // prefetch the URL as if the state was applied pre-empting the users selection
    case 'prefetch':
      {
        const newState = {
          ...state,
          ...(action.changes ? action.changes : {}),
          attributes: {
            ...(state.attributes ? state.attributes : {}),
            ...(action.attributes ? action.attributes : {}),
          },
        };
        const { router, dealAttributes } = action;
        const url = filtersToUrl(newState, dealAttributes);
        router?.prefetch(url);
      }
      return state;

    // prefetch the URL as if the user clicked apply
    case 'prefetch_mobile':
      {
        const { router, dealAttributes } = action;
        const url = filtersToUrl(action.mobileFilters!, dealAttributes);
        router?.prefetch(url);
      }
      return state;

    default:
      return state;
  }
}

const sort = [
  { label: 'Recommended', value: 'recommended' },
  { label: 'Latest', value: 'latest' },
  { label: '# customer reviews', value: 'review_count' },
  { label: 'Avg customer rating', value: 'rating' },
  { label: 'Price: low to high', value: 'price_low' },
  { label: 'Price: high to low', value: 'price_high' },
  { label: 'Ending soon', value: 'ending' },
  { label: 'Quantity remaining', value: 'quantity_remaining' },
];

const skuCards = {
  default: SkuCard,
  alternative: AlternativeToDeal,
  save_for_later: SaveForLater,
};

const skuCardProps = (
  skuCard: SkuCardTypes,
  deal: DealSkuCardType,
  priority: boolean,
  deleteCallback: (dealId: number) => void,
  mutate?: () => void,
  enableVoting?: boolean,
  votedDealId?: number,
) => {
  switch (skuCard) {
    case 'default':
      return {
        key: deal.id,
        deal,
        priority,
      };
    case SKU_CARD_ALTERNATIVE_NAME:
      return {
        key: deal.id,
        deal,
        enableVoting,
        mutate,
        votedDealId,
      };
    case 'save_for_later':
      return {
        key: deal.id,
        deal,
        deleteCallback,
      };
  }
};

const LayoutGrid = ({ children }: { children: ReactNode }) => (
  <div className="grid grid-cols-150 gap-4 md:grid-cols-215 md:gap-5 xl:grid-cols-260 2xl:gap-8">
    {children}
  </div>
);

const useRenderSkuCards = (
  skuCard: SkuCardTypes,
  deals: DealSkuCardType[],
  deleteCallback: (dealId: number) => void,
  mutate: () => void,
  enableVoting?: boolean,
  bottomHeader?: string,
) => {
  return useMemo(() => {
    const votedDealId = deals.find((deal) => deal.has_user_voted)?.id;

    const dealList = deals.map((deal, index) =>
      createElement(
        skuCards[skuCard],
        skuCardProps(
          skuCard,
          deal,
          index === 0,
          deleteCallback,
          mutate,
          enableVoting,
          votedDealId,
        ),
      ),
    );

    if (skuCard === SKU_CARD_ALTERNATIVE_NAME) {
      const roadBlock = (
        <div className="mb-8 flex flex-col gap-6 lg:max-w-screen-2xl lg:flex-row">
          <div className="grow">
            <Roadblock
              type="blue-email-subscribe"
              componentName="alternative-to-roadblock"
              key="alternative-to-roadblock"
            />
          </div>
          <div className="hidden w-full max-w-sm shrink-0 md:block"></div>
        </div>
      );

      dealList.splice(2, 0, roadBlock);

      if (bottomHeader) {
        const bottomHeaderElement = (
          <div className="mb-8 flex flex-col gap-6 lg:max-w-screen-2xl lg:flex-row">
            <div className="grow">
              <hr className="mb-6" />

              <div
                className="appsumo-style-headings space-y-2"
                dangerouslySetInnerHTML={{ __html: bottomHeader }}
              />
            </div>
            <div className="hidden w-full max-w-sm shrink-0 md:block"></div>
          </div>
        );

        dealList.push(bottomHeaderElement);
      }
    }

    return dealList;
  }, [deals, skuCard, deleteCallback, mutate, enableVoting, bottomHeader]);
};

interface BrowseProps {
  initialFilters?: {
    attributes?: {
      group?: string;
      category?: string;
      subcategory?: string;
    };
    query?: string;
  };
  profileParams: {};
  fullWidth: boolean;
  fallbackData?: EsBrowseData[] | undefined;
  layout: BrowseLayoutTypes;
  skuCard: SkuCardTypes;
  className?: string;
  showSort: boolean;
  showFilters: boolean;
  showProductCount: boolean;
  templateSlug?: string;
  userId?: number;
  showTitle?: boolean;
  showBreadcrumbs?: boolean;
  mutateCollection?: () => void;
  enableVoting?: boolean;
  bottomHeader?: string;
  isCollectionPage?: boolean;
}

export default function Browse({
  initialFilters = {},
  profileParams = {},
  fullWidth = false,
  children,
  fallbackData,
  skuCard = 'default',
  layout = 'grid',
  className = '',
  showSort = true,
  showFilters = true,
  showProductCount = true,
  showTitle = false,
  showBreadcrumbs = false,
  templateSlug = '',
  userId,
  mutateCollection,
  enableVoting = false,
  bottomHeader,
  isCollectionPage = false,
}: PropsWithChildren<BrowseProps>) {
  const router = useRouter();
  useOneTap();
  const { removeSavedDeal } = useSavedCart();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileFilters, changeMobileFilter] = useReducer(filterReducer, {});
  const [filters, changeFilter] = useReducer(filterReducer, {}, () => ({
    ...initialFilters,
  }));
  const { attributes = {} } = initialFilters;
  const { data: dealAttributes } = useSWR(DEALATTRIBUTE_PERSONAL_URL);
  const { data, size, setSize, error, isValidating, mutate } =
    useSWRInfinite<EsBrowseData>(
      (pageIndex, previousPageData) => {
        const url = getEsBrowseUrl(filters, profileParams, 20, userId);

        // first page has no search after
        if (pageIndex === 0) {
          return url;
        }

        // end of the road jack
        if (previousPageData && !previousPageData.deals) {
          return null;
        }

        const lastItem =
          previousPageData?.deals[previousPageData?.deals?.length - 1];

        if (lastItem?.search_after) {
          const searchAfter = btoa(lastItem.search_after.join(','));
          return replaceURLParam(
            url,
            'search_after',
            encodeURIComponent(searchAfter),
          );
        }

        return null;
      },
      fetchJson,
      {
        fallbackData,
        revalidateFirstPage: false,
        revalidateIfStale: true,
        revalidateAll: !!mutateCollection,
      },
    );

  const [showLtdQuantitySku, setShowLtdQuantitySku] = useState<boolean>(false);
  const { variant: ltdQuantitySkuVariant } = useExperiment(
    LTD_QUANTITY_SKU_EXPERIMENT_NAME,
    !isCollectionPage, // only enroll collection page (since we dont have auto enroll for this)
  );
  useEffect(() => {
    setShowLtdQuantitySku(isExperimentalVariant(ltdQuantitySkuVariant));
  }, [ltdQuantitySkuVariant]);

  // reset filters when category changes
  useEffect(() => {
    changeFilter({ type: 'reset', state: initialFilters });
  }, [initialFilters]);

  const currentSort = useMemo(
    () => sort.find((s) => s.value === filters.sort)?.label || 'Recommended',
    [filters.sort],
  );

  const pagesLoaded = data?.length || 0;
  const { meta = {} as EsBrowseMeta } = pagesLoaded && data ? data[0] : {};
  const deals = useMemo(
    () =>
      data
        ? ([] as DealSkuCardType[]).concat(...data.map((page) => page.deals))
        : [],
    [data],
  );
  const isLoadingInitialData = typeof data === 'undefined' && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isRefreshing = isValidating && data?.length === size;

  const totalResults = meta?.total_results || 0;
  const totalPages = totalResults ? Math.ceil(totalResults / 20) : 0;
  const isTrendingFallback = meta?.trending_fallback;

  const isSearching = useMemo(
    () => !!initialFilters.query,
    [initialFilters.query],
  );

  const bestMatchDeal = useMemo(() => {
    if (isSearching && deals.length > 0) {
      return deals[0];
    }

    return null;
  }, [deals, isSearching]);

  const dealsToRender = useMemo(() => {
    if (bestMatchDeal) {
      return deals.slice(1);
    }

    return deals;
  }, [deals, bestMatchDeal]);

  /**
   * List sku cards vertically.
   */
  const LayoutList = ({ children }: { children: ReactNode }) => (
    <div
      className={`flex flex-col gap-y-4 ${
        skuCard === SKU_CARD_ALTERNATIVE_NAME
          ? 'mx-auto lg:max-w-screen-2xl'
          : ''
      }`}
    >
      {children}
    </div>
  );

  const layouts = {
    grid: LayoutGrid,
    list: LayoutList,
  };

  // filter parameter has changed
  const onFilterChange = useCallback(
    (keyOrDict: string | {}, value?: string | number | boolean) => {
      let changes;
      if (typeof keyOrDict === 'string') {
        changes = { [keyOrDict]: value };
      } else {
        changes = { ...keyOrDict };
      }
      if (mobileOpen) {
        changeMobileFilter({ type: 'change', changes });
      } else {
        changeFilter({ type: 'change', changes });
        changeFilter({ type: 'apply', router, dealAttributes });
      }
    },
    [dealAttributes, mobileOpen, router],
  );

  const onPrefetchChange = useCallback(
    (keyOrDict: string | {}, value?: string | number | boolean) => {
      let changes;
      if (typeof keyOrDict === 'string') {
        changes = { [keyOrDict]: value };
      } else {
        changes = { ...keyOrDict };
      }
      if (!mobileOpen) {
        changeFilter({ type: 'prefetch', changes, router, dealAttributes });
      }
    },
    [dealAttributes, mobileOpen, router],
  );

  // filter attribute parameter has changed
  const onFilterAttributesChange = useCallback(
    (attributes: {}) => {
      if (mobileOpen) {
        changeMobileFilter({ type: 'change_attributes', attributes });
      } else {
        changeFilter({ type: 'change_attributes', attributes });
        changeFilter({ type: 'apply', router, dealAttributes });
      }
    },
    [dealAttributes, mobileOpen, router],
  );

  // filter attribute parameter has changed
  const onPrefetchAttributesChange = useCallback(
    (attributes: {}) => {
      if (!mobileOpen) {
        changeFilter({ type: 'prefetch', attributes, router, dealAttributes });
      }
    },
    [dealAttributes, mobileOpen, router],
  );

  // initialise mobileFilters from filters and open the mobile filters screen
  const onOpenMobileOpen = useCallback(() => {
    changeMobileFilter({
      type: 'reset',
      state: JSON.parse(JSON.stringify(filters)),
    });
    setMobileOpen(true);
  }, [filters]);

  // apply the select mobile filters (copy from mobileFilters to filters and update URL)
  const onApplyMobileFilters = useCallback(() => {
    setMobileOpen(false);
    changeFilter({
      type: 'reset',
      state: JSON.parse(JSON.stringify(mobileFilters)),
    });
    changeFilter({ type: 'apply', router, dealAttributes });
  }, [dealAttributes, mobileFilters, router]);

  const onPrefetchMobileFilters = useCallback(() => {
    changeFilter({
      type: 'prefetch_mobile',
      mobileFilters,
      router,
      dealAttributes,
    });
  }, [dealAttributes, mobileFilters, router]);

  const onResetFilters = useCallback(() => {
    changeFilter({
      type: 'reset',
      state: {
        attributes: {
          group: initialFilters?.attributes?.group,
          category: initialFilters?.attributes?.category,
          subcategory: initialFilters?.attributes?.subcategory,
        },
      },
    });
    changeFilter({ type: 'apply', router, dealAttributes });
  }, [dealAttributes, initialFilters, router]);

  // reset the mobile filters back to the active filters
  const onResetMobileFilters = useCallback(() => {
    changeMobileFilter({
      type: 'reset',
      state: JSON.parse(JSON.stringify(filters)),
    });
  }, [filters]);

  const onScrollNextPage = (inView: boolean) => {
    if (inView && pagesLoaded < infiniteScrollPages) {
      setSize(size + 1);
    }
  };

  const onSavedItemDelete = useCallback(
    (dealId: number) => {
      removeSavedDeal(templateSlug, dealId);
      mutate((prevData) => {
        const newData = prevData?.map((page) => ({
          ...page,
          deals: page.deals.filter((deal) => deal.id !== dealId),
        }));
        return newData;
      }, false);
    },
    [templateSlug, mutate, removeSavedDeal],
  );

  const manualMutation = useCallback(() => {
    // mutate collection
    if (mutateCollection) {
      mutateCollection();
    }
  }, [mutateCollection]);

  const skuCards = useRenderSkuCards(
    skuCard,
    dealsToRender,
    onSavedItemDelete,
    manualMutation,
    enableVoting,
    bottomHeader,
  );

  const { user } = useUser();

  // force getting the collection data if the user is logged in for voting
  useEffect(() => {
    if (enableVoting && user?.id) {
      manualMutation();
    }
  }, [enableVoting, manualMutation, user?.id]);

  return (
    <SmartCollectionContext.Provider value={{ deals, showLtdQuantitySku }}>
      <div>
        <div className={`${className}`}>
          <div
            className={`mx-auto my-6 w-full max-w-screen-4xl grow px-4 md:px-8`}
          >
            <main
              className={`${
                fullWidth ? '' : 'md:grid md:grid-cols-[250px_1fr] md:gap-4'
              }`}
            >
              {showFilters && (
                <BrowseFilters
                  filters={mobileOpen ? mobileFilters : filters}
                  profileParams={profileParams}
                  aggregations={meta?.aggregations}
                  fullWidth={fullWidth}
                  onPrefetchChange={onPrefetchChange}
                  onChange={onFilterChange}
                  onChangeAttributes={onFilterAttributesChange}
                  onPrefetchChangeAttributes={onPrefetchAttributesChange}
                  open={mobileOpen}
                  onClose={() => setMobileOpen(false)}
                  onApplyMobileFilters={onApplyMobileFilters}
                  onResetMobileFilters={onResetMobileFilters}
                  onPrefetchMobileFilters={onPrefetchMobileFilters}
                />
              )}
              <div>
                {/* Breadcrumbs */}
                {showBreadcrumbs && (
                  <BrowseBreadcrumbs
                    group={attributes.group}
                    category={attributes.category}
                    subcategory={attributes.subcategory}
                  />
                )}
                {/* Collection Title */}
                {showTitle && (
                  <BrowseTitle
                    group={initialFilters.attributes?.group}
                    category={initialFilters.attributes?.category}
                    subcategory={initialFilters.attributes?.subcategory}
                    query={initialFilters.query}
                    numberResults={isTrendingFallback ? 0 : totalResults}
                  />
                )}
                <div>{children}</div>
                {(showProductCount || showFilters || showSort) && (
                  <div className="mb-5 flex items-center justify-end gap-x-2">
                    {showProductCount &&
                      (!isSearching ||
                        (isSearching && !isTrendingFallback)) && (
                        <div className="grow font-bold">
                          {!isLoadingInitialData &&
                            `${thousands(totalResults)} products`}
                        </div>
                      )}
                    {showSort && (
                      <div className="max-md:hidden">
                        <label className="z-10 flex w-80 items-center justify-end gap-x-5">
                          Sort by:
                          <Dropdown
                            key={currentSort}
                            className="grow"
                            options={sort}
                            defaultValue={{
                              value: filters.sort || '',
                              label: currentSort,
                            }}
                            onClick={(option) =>
                              onFilterChange('sort', option.value)
                            }
                          />
                        </label>
                      </div>
                    )}
                    {showFilters && (
                      <div className={`${fullWidth ? '' : 'md:hidden'}`}>
                        <button
                          className="flex items-center rounded-full border border-blue-700 px-4 py-1 text-sm text-blue-700"
                          onClick={onOpenMobileOpen}
                        >
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            height="16"
                            className={`mr-2`}
                          />
                          <span>Filter</span>
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <BrowseAppliedFilters
                  filters={filters}
                  dealAttributes={dealAttributes}
                  profileParams={profileParams}
                  onChange={onFilterChange}
                  onChangeAttributes={onFilterAttributesChange}
                  onClear={onResetFilters}
                />
                {!isSearching ||
                (isSearching && !isTrendingFallback && totalResults > 0) ? (
                  <>
                    {bestMatchDeal && (
                      <div className="mb-8">
                        <BestMatch deal={deals[0]} />
                      </div>
                    )}
                    {createElement(layouts[layout], null, skuCards)}
                  </>
                ) : (
                  <>
                    <h3 className="my-4 font-header text-2xl font-semibold">
                      Search tips
                    </h3>
                    <ul className="list-inside list-disc text-sm">
                      <li>Check for typos or try using broader terms</li>
                      <li>Clear any filters to widen your search</li>
                    </ul>
                    <h3 className="my-4 font-header text-2xl font-semibold">
                      You may also like
                    </h3>
                    {createElement(layouts[layout], null, skuCards)}
                  </>
                )}
                {pagesLoaded < totalPages &&
                  pagesLoaded >= infiniteScrollPages &&
                  !isLoadingMore && (
                    <div className="my-5 flex items-center justify-center">
                      <button
                        onClick={() => setSize(size + 1)}
                        className="w-1/2 rounded-full px-12 py-2 text-center font-header font-bold text-blue-600 hover:bg-blue-200 max-md:w-full"
                      >
                        Show More Results
                      </button>
                    </div>
                  )}
                {pagesLoaded < infiniteScrollPages &&
                  pagesLoaded < totalPages &&
                  !isLoadingMore && (
                    <InView as="div" onChange={onScrollNextPage} triggerOnce>
                      <div className="w-full">&nbsp;</div>
                    </InView>
                  )}
                {(isRefreshing || isLoadingMore) && (
                  <div className="my-5 flex items-center justify-center">
                    <Loader />
                  </div>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    </SmartCollectionContext.Provider>
  );
}
